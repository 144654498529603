<template>
  <div>
    <div class="d-flex justify-content-between">
      <el-button
          :size="size"
          @click="addItem"
          class="mr-2"
          type="primary"
      >
        <font-awesome-icon icon="plus" />
      </el-button>
      <el-button
          :loading="loading"
          :size="size"
          @click="saveTableData"
          type="success"
      >
        {{ $t('system.save') }}
      </el-button>
    </div>
    <DataTable
        :headers="tableHeaders"
        :loading="loading"
        :model="expenses"
        :pagination="false"
        class="table"
    >
      <template v-slot:item.dateAndExpanseCategory="{row, idx}">
        <div class="mx-1 my-2 table__cell">
          <div class="d-flex justify-content-between">
            <el-date-picker
                :class="{'validate-error': !isFieldValid(idx, 'expense_date')}"
                :placeholder="$t('system.date')"
                :size="size"
                class="category-cell__date-picker"
                v-model="row.expense_date"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
            />
            <el-select
                :class="{'validate-error': !isFieldValid(idx, 'expense_category_id')}"
                :placeholder="$t('system.category')"
                :size="size"
                filterable
                v-model="row.expense_category_id"
            >
              <el-option
                  :key="category.id"
                  :label="category.name"
                  :value="category.id"
                  v-for="category in categories"
              />
            </el-select>
          </div>
          <el-input
              :class="{'validate-error': !isFieldValid(idx, 'note')}"
              :placeholder="$t('system.note')"
              :size="size"
              class="w-100 mt-2"
              type="textarea"
              v-model="row.note"
          />
        </div>
      </template>
      <template v-slot:item.purchasedFrom="{row, idx}">
        <div class="mx-1 my-2">
          <el-input
              :class="{'validate-error': !isFieldValid(idx, 'purchased_from')}"
              :placeholder="$t('system.purchased_from')"
              :size="size"
              v-model="row.purchased_from"
          />
        </div>
      </template>
      <template v-slot:item.receipts="{row}">
        <div
            @click.stop
            class="mx-1 my-2 table__cell receipts-cell"
        >
          <template v-if="row.isSaved">
            <el-upload
                :action="uploadUrl(row.id)"
                :before-remove="removeFile"
                :file-list="row.files.map(file => ({...file, name: file.filename || file.name}))"
                :headers="headers"
                :on-change="(file, fileList) => onFilesChange(file, fileList, row)"
                :on-preview="openPreview"
                :on-remove="(file, fileList) => onFilesChange(file, fileList, row)"
                :on-success="onUploadSuccess"
                class="upload-demo"
                disable-transitions
                multiple
            >
              <el-button
                  :size="size"
                  class="upload-button"
                  type="primary"
              >
                {{ $t('system.select_files') }}
              </el-button>
              <!--              <span class="ml-3">{{ row.filesCount }} {{$t('system.files')}}</span>-->
            </el-upload>
          </template>
          <span
              class="no-date-title"
              v-else
          >{{ $t('system.save_to_upload') }}</span>
        </div>
      </template>
      <template v-slot:item.amount="{row, idx}">
        <div class="d-flex justify-content-center mx-1 my-2 amount-cell">
          <el-input-number
              :class="{'validate-error': !isFieldValid(idx, 'amount')}"
              :min="0"
              :size="size"
              @input="onAmountChange(row, ...arguments)"
              controls-position="right"
              v-model.number="row.amount"
          />
        </div>
      </template>
      <template v-slot:item.isBillable="{row}">
        <div class="d-flex justify-content-center mx-1 my-2">
          <el-checkbox
              v-model="row.billable"
          />
        </div>
      </template>
      <template v-slot:item.reimburseTo="{row, idx}">
        <div class="d-flex justify-content-center mx-1 my-2">
          <el-select
              :class="{'validate-error': !isFieldValid(idx, 'reimburse_to')}"
              :size="size"
              class="w-100"
              filterable
              v-model="row.reimburse_to"
          >
            <el-option
                :key="person.id"
                :label="person.name"
                :style="{background: person.color}"
                :value="person.id"
                v-for="person in reimburseToList"
            />
          </el-select>
        </div>
      </template>
      <template v-slot:item.options="{row}">
        <div class="d-flex justify-content-center align-content-center cell_remove">
          <i
              @click.stop="removeItem(row)"
              slot="reference"
          >
            <font-awesome-icon
                icon="times"
            />
          </i>
        </div>
      </template>
    </DataTable>
    <LightBox
        :index="index"
        :items="image"
        :slideshow="false"
        @close="index = null"
        srcThumb="src"
        thumbsPosition="bottom"
        useZoomBar
    />
  </div>
</template>

<script>
import axios from 'axios';
import DataTable from '@/components/Table/DataTable';
import {mapActions, mapGetters} from 'vuex';
import validationMixin from '@/components/WorkOrderComponents/Payments/validationMixin';
import notification from '@/notification/notify';
import {downloadFile} from '@/helpers/files';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import swal from 'sweetalert2';

export default {
  name: 'Expenses',
  components: {
    // FilesList,
    DataTable,
    LightBox: () => import('vue-cool-lightbox'),
  },
  mixins: [validationMixin],
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    headers: {Authorization: 'Bearer ' + localStorage.getItem('token')},
    size: 'mini',
    tableHeaders: [
      {
        key: 'options',
        label: '',
        sort: false,
        width: '36px',
      },
      {
        key: 'dateAndExpanseCategory',
        label: 'system.date_and_expanse_category',
        sort: false,
        width: '350px',
      },
      {
        key: 'purchasedFrom',
        label: 'system.purchased_from',
        sort: false,
      },
      {
        key: 'receipts',
        label: 'system.receipts',
        sort: false,
      },
      {
        key: 'amount',
        label: 'system.amount',
        sort: false,
        width: '120px',
      },
      {
        key: 'isBillable',
        label: 'system.billable',
        sort: false,
        width: '36px',
      },
      {
        key: 'reimburseTo',
        label: 'system.reimburse_to',
        sort: false,
      },
    ],
    reimburseToList: [],
    categories: [],
    image: [],
    index: null,
    loading: false,
  }),
  computed: {
    ...mapGetters('workOrderPayments', ['workOrderExpenses']),
    expenses() {
      return this.workOrderExpenses(this.workOrderId);
    },
  },
  created() {
    this.fetchReimburseToList();
    this.fetchCategories();
  },
  methods: {
    ...mapActions('workOrderPayments', ['removeExpense', 'addExpense']),
    onAmountChange(row, newValue) {
      if (newValue === undefined) {
        row.amount = 0;
      }
    },

    async fetchCategories() {
      try {
        const {data} = await axios.get(`workorders/expense/expense_category`);
        this.categories = data;
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },

    async fetchReimburseToList() {
      try {
        const {data} = await axios.get(`workorders/expense/reimburse_to_list`);
        this.reimburseToList = data;
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },

    uploadUrl(expenseId) {
      return `${process.env.VUE_APP_ROOT_API}/workorders/expense/receipt/${expenseId}`;
    },

    addItem() {
      this.addExpense({workOrderId: this.workOrderId});
    },

    async removeItem(row) {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        this.removeExpense({
          workOrderId: this.workOrderId,
          expenseId: row.id,
        });
      }
    },

    async saveTableData() {
      try {
        this.loading = true;
        await axios.post(`workorders/expense/${this.workOrderId}/expense`, {
          positions: this.expenses.map(item => ({
            ...item,
            receipts: item.files.map(file => file.id),
          })),
        });
        this.validationErrors = {};
        this.expenses.forEach(item => {
          item.isSaved = true;
        });
        notification.notify(
            this.$t('notify.success'),
            this.$t('system.saved'),
            'success');
      } catch (error) {
        if (error.response?.status === 422) {
          this.setValidationData(error.response.data);
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.check_fields'),
              'warning');
        }
      } finally {
        this.loading = false;
      }
    },

    onFilesChange(file, fileList, row) {
      row.files = [...fileList];
    },

    async downloadFile(file) {
      try {
        const response = await axios.get(`workorders/expense/receipt/${file.id}`, {
          responseType: 'blob',
        });
        downloadFile(response, file.name);
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            `<b>${file.name}</b> ${this.$t('system.not_downloaded')}`,
            'error');
      }
    },

    async onUploadSuccess(response, file, fileList) {
      file.id = response.receipt_id;

      notification.notify(
          this.$t('notify.success'),
          `<b>${file.name}</b> ${this.$t('system.uploaded')}`,
          'success');
    },

    removeFile(file, fileList) {
      return axios.delete(`workorders/expense/receipt/${file.id}`)
          .then(() => {
            notification.notify(
                this.$t('notify.success'),
                `<b>${file.name}</b> ${this.$t('system.removed')}`,
                'success');
          })
          .catch(() => {
            notification.notify(
                this.$t('notify.error'),
                `<b>${file.name}</b> ${this.$t('system.not_removed')}`,
                'error');
          });
    },

    async openPreview(file) {
      this.index = 0;
      this.image = [];
      const image = await this.fetchImageForPreview(file.id);
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        this.image.push({
          title: '',
          src: reader.result,
          isDownloaded: true,
        });
      };
    },

    async fetchImageForPreview(id) {
      const {data} = await axios.get(`/workorders/expense/receipt/${id}`, {
        responseType: 'blob',
        params: {type: 'mime_type'},
      });
      return data;
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.table {
  &__cell {
    max-height: 100px;

    .category-cell {
      &__date-picker {
        max-width: 130px;
      }
    }
  }
}
</style>

<style lang="scss">
.table {
  .receipts-cell {
    .upload-button {
      font-weight: 400;
      padding: 5px 10px;
      //border: 1px solid #8c8c8c;
    }

    .el-upload-list {
      max-height: 60px;
      overflow-x: auto;

      .el-upload-list__item {
        transition: none;
      }
    }
  }

  .amount-cell {
    .el-input-number {
      width: 100px;
    }

    //.el-input-group__prepend {
    //  padding: 0 8px;
    //}
    .el-input-number__decrease, .el-input-number__increase {
      display: none;
    }

    .el-input__inner {
      padding: 0 5px
    }
  }
}
</style>

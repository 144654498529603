var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('el-button',{staticClass:"mr-2",attrs:{"size":_vm.size,"type":"primary"},on:{"click":_vm.addItem}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1),_c('el-button',{attrs:{"loading":_vm.loading,"size":_vm.size,"type":"success"},on:{"click":_vm.saveTableData}},[_vm._v(" "+_vm._s(_vm.$t('system.save'))+" ")])],1),_c('DataTable',{staticClass:"table",attrs:{"headers":_vm.tableHeaders,"loading":_vm.loading,"model":_vm.expenses,"pagination":false},scopedSlots:_vm._u([{key:"item.dateAndExpanseCategory",fn:function(ref){
var row = ref.row;
var idx = ref.idx;
return [_c('div',{staticClass:"mx-1 my-2 table__cell"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('el-date-picker',{staticClass:"category-cell__date-picker",class:{'validate-error': !_vm.isFieldValid(idx, 'expense_date')},attrs:{"placeholder":_vm.$t('system.date'),"size":_vm.size,"value":"yyyy-MM-dd","value-format":"yyyy-MM-dd"},model:{value:(row.expense_date),callback:function ($$v) {_vm.$set(row, "expense_date", $$v)},expression:"row.expense_date"}}),_c('el-select',{class:{'validate-error': !_vm.isFieldValid(idx, 'expense_category_id')},attrs:{"placeholder":_vm.$t('system.category'),"size":_vm.size,"filterable":""},model:{value:(row.expense_category_id),callback:function ($$v) {_vm.$set(row, "expense_category_id", $$v)},expression:"row.expense_category_id"}},_vm._l((_vm.categories),function(category){return _c('el-option',{key:category.id,attrs:{"label":category.name,"value":category.id}})}),1)],1),_c('el-input',{staticClass:"w-100 mt-2",class:{'validate-error': !_vm.isFieldValid(idx, 'note')},attrs:{"placeholder":_vm.$t('system.note'),"size":_vm.size,"type":"textarea"},model:{value:(row.note),callback:function ($$v) {_vm.$set(row, "note", $$v)},expression:"row.note"}})],1)]}},{key:"item.purchasedFrom",fn:function(ref){
var row = ref.row;
var idx = ref.idx;
return [_c('div',{staticClass:"mx-1 my-2"},[_c('el-input',{class:{'validate-error': !_vm.isFieldValid(idx, 'purchased_from')},attrs:{"placeholder":_vm.$t('system.purchased_from'),"size":_vm.size},model:{value:(row.purchased_from),callback:function ($$v) {_vm.$set(row, "purchased_from", $$v)},expression:"row.purchased_from"}})],1)]}},{key:"item.receipts",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mx-1 my-2 table__cell receipts-cell",on:{"click":function($event){$event.stopPropagation();}}},[(row.isSaved)?[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":_vm.uploadUrl(row.id),"before-remove":_vm.removeFile,"file-list":row.files.map(function (file) { return (Object.assign({}, file, {name: file.filename || file.name})); }),"headers":_vm.headers,"on-change":function (file, fileList) { return _vm.onFilesChange(file, fileList, row); },"on-preview":_vm.openPreview,"on-remove":function (file, fileList) { return _vm.onFilesChange(file, fileList, row); },"on-success":_vm.onUploadSuccess,"disable-transitions":"","multiple":""}},[_c('el-button',{staticClass:"upload-button",attrs:{"size":_vm.size,"type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('system.select_files'))+" ")])],1)]:_c('span',{staticClass:"no-date-title"},[_vm._v(_vm._s(_vm.$t('system.save_to_upload')))])],2)]}},{key:"item.amount",fn:function(ref){
var row = ref.row;
var idx = ref.idx;
return [_c('div',{staticClass:"d-flex justify-content-center mx-1 my-2 amount-cell"},[_c('el-input-number',{class:{'validate-error': !_vm.isFieldValid(idx, 'amount')},attrs:{"min":0,"size":_vm.size,"controls-position":"right"},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onAmountChange.apply(void 0, [ row ].concat( argsArray ))}},model:{value:(row.amount),callback:function ($$v) {_vm.$set(row, "amount", _vm._n($$v))},expression:"row.amount"}})],1)]}},{key:"item.isBillable",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-content-center mx-1 my-2"},[_c('el-checkbox',{model:{value:(row.billable),callback:function ($$v) {_vm.$set(row, "billable", $$v)},expression:"row.billable"}})],1)]}},{key:"item.reimburseTo",fn:function(ref){
var row = ref.row;
var idx = ref.idx;
return [_c('div',{staticClass:"d-flex justify-content-center mx-1 my-2"},[_c('el-select',{staticClass:"w-100",class:{'validate-error': !_vm.isFieldValid(idx, 'reimburse_to')},attrs:{"size":_vm.size,"filterable":""},model:{value:(row.reimburse_to),callback:function ($$v) {_vm.$set(row, "reimburse_to", $$v)},expression:"row.reimburse_to"}},_vm._l((_vm.reimburseToList),function(person){return _c('el-option',{key:person.id,style:({background: person.color}),attrs:{"label":person.name,"value":person.id}})}),1)],1)]}},{key:"item.options",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-content-center align-content-center cell_remove"},[_c('i',{attrs:{"slot":"reference"},on:{"click":function($event){$event.stopPropagation();return _vm.removeItem(row)}},slot:"reference"},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)])]}}])}),_c('LightBox',{attrs:{"index":_vm.index,"items":_vm.image,"slideshow":false,"srcThumb":"src","thumbsPosition":"bottom","useZoomBar":""},on:{"close":function($event){_vm.index = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }